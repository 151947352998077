body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100vh;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  background-color: rgba(192, 192, 192, 0.12);
  /* The image used */
  background-image: url("../Images/AUK%20Insight%20Portal%20_%20Vector%20Background-Light.svg");

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#Layer_1 {
  opacity: 0.3 !important;
}
