#deliverynotificationsmap {
  height: 100%;
  width: 60%;
}

@media only screen and (max-width: 1200px) {
  #deliverynotificationsmap {
    width: 60%;
  }
}

@media only screen and (max-width: 550px) {
  #deliverynotificationsmap {
    width: 100%;
  }
}
