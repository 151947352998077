.my-container {
    width: 100%;
    height: 81vh;
    justify-content: space-around;
    position: absolute;
    top: 62px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.grid-collection {
    display: inline-block;
    padding-top: 0.5vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    padding-bottom: 1vw;
    background-color: rgba(0, 66, 137, 0.1) !important;
}

.grid-container-other {
    width: 6vw;
    height: 8.5vw;
    display: grid;
    grid-template-columns: 5.95vw;
    grid-template-rows: 5.95vw;
    grid-gap: 0.5vw;
}

.grid-container-main {
    width: 25vw;
    height: 25vw;
    display: grid;
    grid-template-columns: 25vw;
    grid-template-rows: 25vw;
    margin-right: 0.5vw;
    float: left;
}

.grid-container-main img {
    width: 25vw;
    height: 25.5vw;
    transition: transform 0.2s;
}

.grid-container-main img:hover {
    transform: scale(1.01);
}

.delete-homepage-link {
    position: absolute;
    z-index: 50;
}
.supportedhomepagelinkdiv {
    overflow: hidden;
}
.supportedhomepagelink {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.2s;
}

.supportedhomepagelink:hover {
    transform: scale(1.02);
}

.main-panel-link {
    background-color: initial;
}

.grid-container-main div,
.grid-container-other div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 0 0;
    font-size: 30px;
}

.grid-container-main div {
    width: 25vw;
    height: 25.5vw;
}

.grid-container-other div,
.add-link-button {
    width: 6vw;
    height: 6vw;
}

.main-panel-link {
    position: relative;
}

.add-link-button {
    background-color: #fafafa;
    color: #004289;
}

.add-link-button:hover {
    color: #e9aa00;
}

.assign-homepage-image {
    width: 50px;
    height: 50px;
}

.link-title {
    font-size: 1.5vw;
}

.desktop-banner {
    display: none;
}

.mobile-banner {
    display: block;
    margin-bottom: 0.5vw;
}

.desktop-banner img, .mobile-banner img {
    max-width: 100%;
}

@media (min-width: 640px) {

    .desktop-banner {
        display: block;
        margin-bottom: 1vw;
    }

    .mobile-banner {
        display: none;
    }

}

@media only screen and (orientation: portrait) {
    .link-title {
        font-size: 6vw;
    }

    .grid-collection {
        background-color: initial !important;
    }

    .grid-container-other {
        width: 98vw;
        height: 98vw;
        grid-template-columns: 48.5vw 48.5vw;
        grid-template-rows: 48.5vw 48.5vw;
        grid-gap: 1vw;
    }

    .grid-container-main {
        width: 98vw;
        height: 98vw;
        grid-template-columns: 98vw;
        grid-template-rows: 98vw;
        margin-left: 0.5vw;
        margin-bottom: 1vw;
        float: left;
    }

    .grid-container-main img {
        width: 98vw;
        height: 98vw;
    }

    .grid-container-main div {
        width: 98vw;
        height: 98vw;
    }

    .grid-container-other div,
    .add-link-button {
        width: 48.5vw;
        height: 48.5vw;
    }
}
