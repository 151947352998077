.download__report__button {
    border-color: #e9aa00;
    color: #e9aa00;
    font-size: 8px !important;
    margin-left: 2px;
    margin-right: 2px;
}

.download__report__button:hover {
    color: white;
    background-color: #e9aa00;
}
