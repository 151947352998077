.pdf__display {
    height: 90.5%;
    width: 100%;
    overflow-y: scroll;
}

.pdf__page__display {
    margin-bottom: 10px;
}

.page__number__pdf {
    display: inline;
    width: 65px;
    height: 30px;
    margin-left: 5px;
    margin-right: 2px;
}

.pdf__display__toolbar {
    padding: 10px;
    background-color: #fffefc;
    height: 65px;
    border-bottom: solid;
    border-width: thin;
    border-color: #e9aa00;
}

.pdf__zoom__buttons {
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap;
}

#pdf__close__button {
    border-color: red;
    color: red;
}

#pdf__close__button:hover {
    color: white !important;
    background-color: red;
}

.pdf__display__buttons {
    margin-left: 2px;
    margin-right: 2px;
    padding: 11px;
    border-color: #e9aa00;
    color: #e9aa00;
    font-size: 12px !important;
}

.virtuoso__page__div {
    will-change: transform !important;
}

.pdf__display__buttons:hover {
    color: white !important;
    background-color: #e9aa00;
}

.pdf__pagination {
    float: left;
    margin-left: 20px;
    margin-bottom: 2px;
    white-space: nowrap;
}

.pdf__pagination > li > a,
.pdf__pagination > li > span {
    height: 45px !important;
}

.pdf__pagination__group {
    width: 100%;
    height: 100%;
    float: left;
    display: flex;
    justify-content: center;
}

.pdf__pagination__group .page-link {
    border-color: #e9aa00 !important;
    color: #e9aa00 !important;
    font-size: 12px;
}

.pdf__pagination__group .page-link:hover {
    color: white !important;
    background-color: #e9aa00;
}

.react-pdf__Page__canvas {
    margin: auto !important;
}

@media only screen and (max-width: 600px) {
    .pdf__pagination__group {
        display: inline-block;
    }

    .pdf__pagination {
        margin-left: 2px;
        margin-bottom: 5px;
        width: 100%;
    }

    .pdf__zoom__buttons {
        width: 100%;
    }

    .pdf__display__buttons {
        float: left;
    }

    .pdf__display__toolbar {
        height: 110px;
    }
}
