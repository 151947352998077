.form-validation {
  color: red;
  font-size: small;
}

#signup-form-button {
  margin-left: 20px;
}

#signup-form-fields {
  padding: 10px;
  margin: 10px;
  height: 60vh;
  max-width: 90vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

.container-fluid {
  margin-top: 10px;
}

.custom-checkbox {
  margin-bottom: 15px;
}

.auk-control-input {
  padding-left: 5px;
}
