.main-loader {
  width: 3rem;
  height: 3rem;
  color: #004289;
}

.main-loader-img {
  width: 3rem;
  height: 3rem;
  color: #004289;
}

.loading-animation {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.loader-background {
  margin: 0;
  z-index: 100;
  position: absolute;
  background-color: rgba(134, 134, 134, 0.7);
  width: 100%;
  height: 100%;
}
