.upload-home-page-link-button {
  width: 100%;
  height: 100%;
  background-color: #004289;
  color: white;
  border: solid;
  border-radius: 3px;
  padding: 5px;
  margin-top: 5px;
}

.homepage-links-container {
  overflow-x: scroll;
}

.upload-home-page-link-button:hover {
  color: #e9aa00;
}

.homepage-link-image {
  width: 50px;
  height: 50px;
}

.homepage-link-table tbody tr:hover {
  color: white;
  background-color: #004289 !important;
  cursor: pointer;
}
