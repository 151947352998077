tbody tr:nth-child(odd) {
  background-color: rgba(228, 225, 225, 0.7) !important;
}

thead,
tbody tr:nth-child(even) {
  background-color: rgba(255, 251, 251, 0.7);
}

.tab-link,
.navbar-item {
  color: white;
  background-color: #004289;
}

.tab-content {
  margin-top: 20px;
}

.tab-link:hover {
  color: #e9aa00 !important;
}

.admin-data-table {
  height: 70vh !important;
}

.admin-data-table tbody tr:hover {
  color: white;
  background-color: #004289 !important;
}

tbody tr:hover {
  cursor: pointer;
}

.admin-container {
  position: relative;
  top: 70px;
  margin-top: 10px;
}

.tab-pane {
  position: relative;
}

.admin-pagination-nav {
  position: fixed;
  bottom: 0;
  width: 10%;
  left: 45%;
  opacity: 0.8;
}

.admin-container-content-centre {
  height: 92vh;
}

@media only screen and (max-width: 550px) {
  .nav-item {
    width: 45% !important;
    margin: 0 !important;
  }

  .nav-item a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
