.deliverynotificationspanel {
    float: left;
    width: 40%;
    height: 100%;
}

.card-header-info-left {
    width: 40%;
    padding-left: 15px;
}

.card-header-info-right {
    width: 60%;
}

.product-table-display {
    overflow-x: scroll;
}

.delivery-date-picker {
    width: 50px !important;
    margin-bottom: 5px;
}

.early {
    color: green;
}

.late {
    color: red;
}

.drop-container {
    margin-right: 15px;
    margin-bottom: 5px;
    font-size: 12px;
}

.delivery-filters label,
.delivery-filters input {
    padding-right: 5px;
    margin-right: 5px;
    font-size: 15px;
}

.image-pod-collection {
    display: inline-block;
}

.images-pod-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50%;
    grid-gap: 1vw;
    padding-right: 15px;
}
.images-pod-container img {
    width: 100%;
}

.main-card-panel {
    margin-top: 15px;
}

.delivered {
    background-color: rgba(223, 255, 223, 0.2) !important;
    margin-right: 1px;
    margin-left: 1px;
}

.partiallydelivered {
    background-color: rgba(248, 221, 148, 0.2) !important;
    margin-right: 1px;
    margin-left: 1px;
}

.intransit {
    background-color: rgba(181, 230, 245, 0.2) !important;
    margin-right: 1px;
    margin-left: 1px;
}

.failed {
    background-color: rgba(245, 181, 181, 0.2) !important;
    margin-right: 1px;
    margin-left: 1px;
}

.deliverynotification-filters {
    padding-left: 15px !important;
    border-color: lightgray !important;
    border-width: 2.5px !important;
    border: solid;
    border-radius: 5px;
}

.delivery-select-title {
    color: #004289;
    font-size: 12px;
}

.deliverynotification-filters {
    margin-bottom: 2%;
    padding: 2px;
    width: 96%;
    cursor: pointer;
}

.deliverynotification-form {
    position: relative;
    margin-top: 5%;
    margin-left: 5%;
    height: 95%;
    overflow-y: scroll;
}

@media only screen and (max-width: 1200px) {
    .deliverynotificationspanel {
        width: 40%;
    }

    .card-header-info-left {
        width: 100%;
        padding-left: 15px;
    }

    .card-header-info-right {
        width: 100%;
        padding-left: 15px;
    }
}

@media only screen and (max-width: 550px) {
    .deliverynotificationspanel {
        width: 100%;
    }

    .card-header-info-left {
        width: 100%;
        padding-left: 15px;
    }

    .card-header-info-right {
        width: 100%;
        padding-left: 15px;
    }
}
