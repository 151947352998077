#info-box {
  position: fixed;
  top: 75px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10;
  min-width: 150px;
  min-height: 225px;
  max-height: 30%;
  border-radius: 10px;
  overflow-y: scroll;
}

.ratekey-colours {
  width: 10px;
  height: 10px;
  margin: 5px;
  float: left;
}

#ratekey-title {
  padding: 4px;
}

@media only screen and (max-width: 1200px) {
  #info-box {
    min-width: 100px;
    min-height: 110px;
    width: 100px;
    height: 110px;
    overflow: scroll;
  }

  #ratekey-title {
    font-size: 10px;
  }
}

@media only screen and (max-width: 550px) {
  #info-box {
    display: none;
    position: fixed;
    top: 120px;
    min-width: 75px;
    min-height: 150px;
    width: 75px;
    height: 150px;
    overflow: scroll;
  }

  #ratekey-title {
    font-size: 10px;
  }
}
