.draw-boundary-button {
  width: 100%;
  height: 100%;
  background-color: #004289;
  color: white;
  border: solid;
  border-radius: 3px;
  padding: 5px;
  margin-top: 5px;
}

.draw-boundary-button:hover {
  color: #e9aa00;
}
