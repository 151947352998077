html {
  scroll-behavior: smooth;
}

.usermaint-ifsno-readonly {
  background-color: #e9ecef !important;
}

.upload-analysis-button {
  width: 100%;
  height: 100%;
  background-color: #004289;
  color: white;
  border: solid;
  border-radius: 3px;
  padding: 5px;
  margin-top: 5px;
}

.shapefile-upload-maintenance {
  margin: 5px;
}

.um-type-select {
  min-width: 75px;
}

.upload-analysis-button:hover {
  color: #e9aa00;
}

.upload-analysis-body {
  overflow: scroll;
  max-height: 700px;
  user-select: none;
  height: 60vh;
}

.mapping-analysis-select {
  min-width: 150px;
}

.analysis-report-upload {
  min-width: 250px;
}

.analysis-field-select {
  min-width: 150px;
}

.analysis-year-select {
  min-width: 100px;
}

.analysis-type-select {
  min-width: 150px;
}

.analysis-name-input {
  min-width: 150px;
}

#confirm-analysis-delete {
  z-index: 1600 !important;
}

.user-maintenance-modal-body {
  height: 50vh;
  overflow-y: scroll;
}
