header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100 !important;
}

.nav-header {
    background-color: white;
}

.navbar {
    z-index: 10;
}

.header-link,
.navbar-item {
    color: #004289 !important;
    background-color: white;
    font-weight: bold;
    font-size: 14px;
}

.signedin-user-title {
    color: #004289 !important;
}

.header-link:hover {
    color: #e9aa00 !important;
}

button {
    background: transparent;
    outline: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
}

.navbar-brand-main-logo {
    width: 100px;
}
