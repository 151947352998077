#optional__expiration__form__label {
    color: red;
    font-size: 10px;
    margin-right: 5px;
}

#expiration__help__text {
    font-size: 12px;
}

#expiration__help__label :hover {
    cursor: pointer;
}
