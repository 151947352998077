.soil__health__page {
    position: fixed;
    top: 58px;
    bottom: 0;
    left: 0;
    right: 0;
}

.soil__health__parent__container {
    position: fixed;
    top: 138px;
    bottom: 0;
    left: 0;
    right: 0;
}

.soil__health__div__container {
    width: 50%;
    height: 100%;
}

.float-right.soil__health__div__container {
    overflow-y: scroll;
}

.soil__health__input__dropdowns {
    padding: 8px;
    background-color: #fffefc;
    height: 80px;
    font-size: 14px;
}

.soil__health__input__dropdowns > div {
    padding: 5px;
    width: 25%;
    display: inline-block;
}

@media only screen and (max-width: 800px) {
    .soil__health__map {
        display: none;
    }

    .soil__health__div__container {
        width: 100%;
    }

    .user__search___input__group input {
        width: 50%;
    }

    .user__search___input__group button {
        overflow: hidden;
        width: 50%;
    }

    .view__report__button {
        font-size: 2vw !important;
    }
}
