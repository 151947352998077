.deliverynotifications-mainworkspace {
    position: fixed;
    top: 58px;
    bottom: 0;
    left: 0;
    right: 0;
}

#deliverynotificationsmobilenav {
    display: none;
    z-index: 95;
    background-color: white;
    position: fixed;
    top: 58px;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
}

#deliverynotificationsmobilenav span {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 9px;
}

@media only screen and (max-width: 550px) {
    #deliverynotificationsmobilenav {
        display: block;
    }

    .deliverynotifications-mainworkspace {
        top: 98px;
    }
}
