.maptoolbox-button {
  color: #e9aa00;
  border-color: #e9aa00;
  border-style: solid;
  border-width: thin;
  border-radius: 3px;
  margin: 2px;
  margin-right: 7px;
  width: 100%;
  outline: 0 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#buttoncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.maptoolbox-button.selected {
  color: white;
  background-color: #e9aa00;
}

.maptoolbox-button:hover {
  background-color: #e9aa00;
  border-color: #e9aa00;
  color: white;
}

#saveclearmeasurebutton {
  background-color: #e9aa00;
  border-color: #e9aa00;
  color: white;
}

#clearsaveboundarybutton {
  background-color: #e9aa00;
  border-color: #e9aa00;
  color: white;
}

#saveclearmarkerbutton {
  background-color: #e9aa00;
  border-color: #e9aa00;
  color: white;
}

#saveclearzonesbutton {
  background-color: #e9aa00;
  border-color: #e9aa00;
  color: white;
}

#overlayselect {
  margin: 2px;
  width: 99.25%;
  cursor: pointer;
}

#maptoolboxdiv {
  position: fixed;
  top: 65px;
  margin: auto auto;
  left: 25%;
  right: 10%;
  min-width: 20%;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  background-image: url("../Images/AUK%20Insight%20Portal%20_%20Vector%20Background-Light.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(0.7);
}

@media only screen and (max-width: 1200px) {
  #maptoolboxdiv {
    left: 35%;
  }

  .maptoolbox-button {
    font-size: 14px;
    height: 30px;
  }
}

@media only screen and (max-width: 550px) {
  #maptoolboxdiv {
    top: 110px;
    overflow-y: scroll;
    left: 0;
    display: none;
  }

  .maptoolbox-button {
    font-size: 12px;
    height: 30px;
  }
}
