.date__range__picker {
    float: right;
    border-style: solid;
    border-color: lightgray;
    border-width: thin;
    border-radius: 3px;
}

.date__range__picker_form__group {
    float: left;
    width: 50%;
    padding-left: 2%;
    padding-right: 2%;
    margin: 0;
}

.date__range__picker .react-datepicker__input-container > input {
    width: 100%;
}
