#deliverynotifications-info-box {
    position: fixed;
    top: 75px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 10;
    min-width: 150px;
    min-height: 225px;
    border-radius: 10px;
}
.delivery-icons {
    width: 20px;
}

.delivery-key {
    padding: 5px;
}

.deliverykey-text {
    margin-left: 5px;
    font-size: 15px;
}

@media only screen and (max-width: 1200px) {
    #deliverynotifications-info-box {
        min-width: 100px;
        min-height: 110px;
        width: 100px;
        height: 110px;
        overflow: scroll;
        white-space: nowrap;
    }

    .delivery-icons {
        width: 20px;
    }

    .deliverykey-text {
        font-size: 12px;
    }
}

@media only screen and (max-width: 550px) {
    #deliverynotifications-info-box {
        display: none;
        position: fixed;
        top: 120px;
        min-width: 75px;
        min-height: 195px;
        width: 75px;
        height: 195px;
        overflow: scroll;
        white-space: nowrap;
    }
}
