.soilhealthpanel {
  float: left;
  width: 25%;
  height: 100%;
}

.soilhealth-form {
  position: relative;
  margin-top: 5%;
  margin-left: 5%;
  height: 87%;
  overflow-y: scroll;
  font-size: 14px;
}

.form-control {
  font-size: 14px;
}

.soilhealth-filters {
  margin-bottom: 2%;
  padding: 2px;
  width: 96%;
  cursor: pointer;
  font-size: 14px;
}

.form-control {
  cursor: pointer;
}

.gcistationlistselect {
  opacity: 0.7;
  padding-left: 15px;
  margin-right: 15px;
  margin-bottom: 5px;
  border-color: lightgray !important;
  border-width: 2.5px !important;
  border: solid;
  border-radius: 5px;
}

.zone-name {
  color: white;
}

.rates-input-validation {
  color: red;
  width: 90%;
}

.input-with-button {
  margin-right: 15px;
  margin-bottom: 5px;
}

.input-group {
  margin-bottom: 5px !important;
}

#download-buttons {
  position: fixed;
  bottom: 0;
  height: 8%;
  width: 25%;
}
.download-zip-button {
  width: 50%;
  height: 100%;
  background-color: #004289;
  color: white;
  border: solid;
}

.info-icon img {
  height: 12px;
  padding-left: 5px;
}

.select-title {
  color: #004289;
}

.download-zip-button:hover {
  color: #e9aa00;
}

@media only screen and (max-width: 1200px) {
  .soilhealthpanel {
    width: 35%;
  }

  #download-buttons {
    width: 35%;
  }

  .download-zip-button {
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media only screen and (max-width: 550px) {
  .soilhealthpanel {
    width: 100%;
  }

  #download-buttons {
    width: 100%;
  }

  .download-zip-button {
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
