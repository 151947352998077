.section__title {
    font-weight: bold;
    font-size: 13px;
}

.section__title__group {
    margin-top: 10px;
}

.soil__health__panel__reports .card-header {
    padding-bottom: 5px;
    padding-top: 0;
}

.soil__health__panel__reports .card-body {
    background-color: rgba(192, 192, 192, 0.01);
    background-image: url("../../../Images/AUK%20Insight%20Portal%20_%20Vector%20Background-Light.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 2px;
    padding-top: 10px;
}

@media only screen and (max-width: 1300px) {
    .section__title__group {
        width: 50%;
        overflow: hidden;
    }

    .left__grouping_titles svg {
        display: none;
    }

    .section__title {
        font-size: small;
        white-space: nowrap;
        overflow-x: hidden !important;
    }

    .section__title__group button {
        float: right;
    }
}

@media only screen and (max-width: 800px) {
    .date__range__picker_form__group label {
        white-space: nowrap;
        overflow: hidden !important;
        width: 100%;
    }

    .left__grouping_titles {
        padding: 10px;
    }

    .soil__health__panel__reports .card-header {
        padding: 1px;
    }
}
