.measure-buttons {
  color: #e9aa00;
  width: 48.5%;
  height: 100px;
  border-color: #e9aa00;
  background-color: white;
  border-style: solid;
  border-width: thin;
  border-radius: 3px;
  margin: 2px;
  outline: 0 !important;
}

.measure-buttons:hover {
  background-color: #e9aa00;
  border-color: #e9aa00;
  color: white;
}
