.gcigraphpanel {
  width: 1012px;
  height: 740px;
  margin: 0 auto;
  display: block;
}

#gci-modal-body {
  background-image: url("../Images/AUK%20Insight%20Portal%20_%20Vector%20Background-Light.svg");
  overflow: scroll;
  height: 70vh;
}
