.ifs-customers-modal-body {
  height: 40vh;
  overflow-y: scroll;
  padding: 5px;
}

.ifs-customer-search-inputs {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
