.main-background-image {
    position: fixed;
    opacity: 0.3;
}

.custom-select,
.form-control,
.user__search___input__group > input {
    font-size: 12px;
}

.btn-outline-secondary {
    color: #e9aa00;
    border-color: #e9aa00;
    font-size: 12px;
}

.btn-outline-secondary:hover {
    background-color: #e9aa00;
    border-color: #e9aa00;
    color: white;
}

.email-not-verified-container {
    justify-content: space-around;
    position: absolute;
    top: 69px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.email-not-verified-background {
    background-color: white;
    box-shadow: 5px 5px 10px 10px #888888;
    padding: 30px;
}

.account-not-linked-container {
    justify-content: space-around;
    position: absolute;
    top: 69px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.account-not-linked-background {
    background-color: white;
    box-shadow: 5px 5px 10px 10px #888888;
    padding: 30px;
}

.app-not-authorised-container {
    justify-content: space-around;
    position: absolute;
    top: 69px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.app-not-authorised-background {
    background-color: white;
    box-shadow: 5px 5px 10px 10px #888888;
    padding: 30px;
}

button {
    outline: 0 !important;
}
