#zonesinfomap {
  width: 60%;
  height: 100%;
}

#zonespropertytablecontainer {
  float: left;
  height: 100%;
  width: 40%;
  overflow-y: scroll;
}

#zonespropertytablecontainer table {
  width: 98%;
  margin: 2px;
}

#zonespropertycontainer {
  position: relative;
  width: 100%;
  height: 300px;
}

#zonemetainfo {
  overflow-x: scroll;
}

#zonemetainfo table {
  width: 100%;
}

.infobox-help {
  font-weight: normal;
}

.infobox-help:hover {
  cursor: pointer;
}
