#map {
  height: 100%;
  width: 75%;
}

.gm-style .gm-style-iw-t::after {
  background: none;
  box-shadow: none;
}

.fieldarealabel {
  color: white;
  padding: 5px;
  border-radius: 6px;
}

.allfieldarealabel {
  color: white;
  padding: 5px;
  border-radius: 6px;
}

.overlaymeasurelabel {
  color: white;
  font-size: 20px;
}

.measurelengthlabel {
  color: white;
  font-size: 20px;
}

.measurearealabel {
  color: white;
  font-size: 20px;
}

@media only screen and (max-width: 1200px) {
  #map {
    width: 65%;
  }
}

@media only screen and (max-width: 550px) {
  #map {
    width: 100%;
  }
}
