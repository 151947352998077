.glgm-search-data-table {
  width: 100%;
  overflow-x: scroll;
  height: 90%;
}

.glgm-search-data-table tbody tr:hover {
  color: white;
  background-color: #004289 !important;
}

.glgm-site-search-modal-body {
  height: 50vh;
  overflow-y: scroll;
}
